// https://docs.magicthegathering.io/

import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Content = () => {
  const [cards, setCards] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://api.magicthegathering.io/v1/cards?setName=Kaldheim`
      )
      setCards(result.data.cards)
    }
    fetchData()
  }, [])

  return (
    <div>
      {cards.map((card) => (
        <div key={card.id}>
          <h2>{card.name}</h2>
          <img alt={card.name} src={card.imageUrl}></img>
        </div>
      ))}
    </div>
  )
}

export default Content
